.___Desktop_header__rCI6P {
  position: sticky;
  width: 100%;
  min-width: 100%;

  padding-right: 20px;
  top: 0px;
  left: 0;

  background: #ffffff;
  transition: all 0.35s cubic-bezier(0.2, 0.8, 0.2, 1);
  transform: translateY(0px);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);

  border-radius: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.___Desktop_content__qSLSj {
  width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.___Desktop_contentLeft__ugwHy {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  gap: 40px;
}

.___Desktop_naviagtionRow__eNQP9 {
  display: flex;
  height: 100%;
  gap: 38px;
  align-items: center;
}
.___Desktop_gnb__oC7TW {
  height: 100%;
}
.___Desktop_gnb__oC7TW li {
  position: relative;
}

.___Desktop_gnb__oC7TW li .___Desktop_link__y9Iv7 {
  display: flex;
  align-items: center;
  height: 70px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #333;
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
}

span.___Desktop_link__y9Iv7 {
  cursor: default;
}

.___Desktop_gnb__oC7TW li .___Desktop_link__y9Iv7:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0px;
  width: 70px;
  height: 4px;
  border-radius: 4px;
  margin-bottom: 0px;
  background: #fe6b37;
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  transform: scaleX(0);
  transform-origin: bottom center;
  margin-left: -35px;
}
.___Desktop_gnb__oC7TW li:hover .___Desktop_link__y9Iv7:before {
  transform: scaleX(1);
}
.___Desktop_depth2__JyD_9 {
  position: absolute;
  top: -300px;
  z-index: 2;
  transition: 0s 0.2s ease-in-out;
  margin-left: 40px;
}
.___Desktop_gnb__oC7TW li .___Desktop_depth2__JyD_9:before {
  content: '';
  position: absolute;
  top: -11px;
  left: -90px;
  width: 180px;
  height: 12px;
}
.___Desktop_gnb__oC7TW li .___Desktop_depth2__JyD_9 ul {
  width: 180px;
  margin-left: -90px;
  padding: 6px 0;
  background: #fff;
  border-radius: 20px;
  box-shadow: 3.5px 6.1px 19px 0 rgba(43, 29, 72, 0.24);
  opacity: 0;
  transition: 0.2s ease-in-out;
}
.___Desktop_gnb__oC7TW li .___Desktop_depth2__JyD_9 ul li {
  float: none;
}
.___Desktop_gnb__oC7TW li .___Desktop_depth2__JyD_9 ul li + li {
  margin-left: 0;
  border-top: 1px solid #ddd;
}
.___Desktop_gnb__oC7TW li .___Desktop_depth2__JyD_9 ul li a {
  display: block;
  height: 51px;
  padding-top: 0;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  text-align: center;
  line-height: 50px;
}
.___Desktop_gnb__oC7TW li .___Desktop_depth2__JyD_9 ul li.___Desktop_on___oY13 a,
.___Desktop_gnb__oC7TW li .___Desktop_depth2__JyD_9 ul li a:hover {
  color: #fe6b37;
}
.___Desktop_gnb__oC7TW li .___Desktop_depth2__JyD_9 ul li a:before {
  display: none !important;
}
.___Desktop_gnb__oC7TW li:hover .___Desktop_depth2__JyD_9 {
  top: 80px;
  transition: 0s ease-in-out;
}
.___Desktop_gnb__oC7TW li:hover .___Desktop_depth2__JyD_9 ul {
  opacity: 1;
}
.___Desktop_gnb__oC7TW li .___Desktop_depth2__JyD_9:hover {
  display: block;
}
.___Desktop_sign-area__gbX2U {
  display: inline-flex;
  gap: 20px;
  padding: 15px 0px;
  height: 100%;
}

.___Desktop_cs-area__W6HAK {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.___Desktop_btn-cs-center__6a5_6 {
  display: inline-flex;
  align-items: center;

  position: relative;

  font-size: 24px;
  font-weight: 500;
  color: #000;
  line-height: 20px;
  vertical-align: top;
}

.___Desktop_btn-cs-icon__DG9FB {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  background: #e7e7e7;
}

.___Desktop_signButtons__IJjp8 {
  display: inline-flex;
  align-items: center;
  height: 100%;
  gap: 10px;
}

.___Desktop_signInButton__iyq3p {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 27px;
  font-size: 18px;
  color: #000;
  line-height: 52px;
  transition: 0.2s ease-in-out;
  cursor: default;
  font-weight: 500;
  padding-right: 12px;
}
.___Desktop_signInButton__iyq3p:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 30px;
  margin: 0px auto;
}

.___Desktop_sign-area__gbX2U .___Desktop_btn___yfX2 + .___Desktop_btn___yfX2 {
  margin-left: 10px;
}
.___Desktop_sign-area__gbX2U .___Desktop_loginDetail__u3S1p {
  position: absolute;
  top: -300px;
  left: 50%;
  z-index: 2;
  width: 180px;
  margin-left: -90px;
  padding: 6px 0;
  background: #fff;
  border-radius: 20px;
  box-shadow: 3.5px 6.1px 19px 0 rgba(43, 29, 72, 0.24);
  opacity: 0;
  transition:
    opacity 0.2s ease-in-out, top 0s 0.3s ease-in-out;
}
.___Desktop_loginDetail__u3S1p a {
  display: block;
  height: 51px;
  padding-top: 0;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  text-align: center;
  line-height: 50px;
}
.___Desktop_loginDetail__u3S1p a + a {
  border-top: 1px solid #ddd;
}

.___Desktop_loginDetail__u3S1p a:hover {
  color: #fe6b37;
}
.___Desktop_signInButton__iyq3p:hover .___Desktop_loginDetail__u3S1p {
  top: 74px;
  opacity: 1;
  transition:
    opacity 0.2s ease-in-out, top 0s ease-in-out;
}

.___Desktop_demoButton__9YB0H {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  padding: 0px 24px;
  border-radius: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 52px;
  text-align: center;
  transition: 0.2s ease-in-out;
}
@keyframes ___Desktop_slidePhrase__0MoKj {
  0% {
    transform: translateY(-40px);
  }
  35% {
    transform: translateY(-40px);
  }
  50% {
    transform: translateY(0px);
  }
  85% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(40px);
  }
}
.___Desktop_signUpButton__YL1iY {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  /* width: 215px; */
  color: white;
  border: 1px solid #fe6b37;
  background: #fe6b37;
  padding: 0px 24px;
  border-radius: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 52px;
  text-align: center;
  transition: 0.2s ease-in-out;
  transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  overflow: hidden;
}

._______Desktop_logo-symbol__4zY_s,
._______Desktop_logo-letter__DOlA5 {
  transition: all 0.4s cubic-bezier(0.2, 0.8, 0.25, 1);
}

._______Desktop_logo-symbol__4zY_s {
  opacity: 1;
  transform: translate(0px, 0px);
}
._______Desktop_logo-letter__DOlA5 {
  transform: translate(0px, 0px);
}
._______Desktop_hide__z4d0v {
  opacity: 0;
  transform: translate(-15px, 0px) scale(0.6);
}
._______Desktop_enlarge__GEc_p {
  transform: translate(-20px, 0px) scale(1.3);
}

.___Desktop2_header__vx8QJ {
  position: sticky;
  width: 100%;
  min-width: 100%;

  top: 0px;
  left: 0;

  background: rgba(255,255,255,0.93333);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);

  transition: all 0.35s cubic-bezier(0.2, 0.8, 0.2, 1);
  transform: translateY(0px);
  backdrop-filter: blur(8px);

  border-radius: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.___Desktop2_content__Qro7r {
  width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.___Desktop2_contentLeft__zAIiq {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  gap: 40px;
}

.___Desktop2_naviagtionRow__zVhcQ {
  display: flex;
  height: 100%;
  gap: 38px;
  align-items: center;
}
.___Desktop2_gnb__DAxOJ {
  height: 100%;
}
.___Desktop2_gnb__DAxOJ li {
  position: relative;
}

.___Desktop2_gnb__DAxOJ li .___Desktop2_link___K0B7 {
  display: flex;
  align-items: center;
  height: 70px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #333;
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
}

span.___Desktop2_link___K0B7 {
  cursor: default;
}

.___Desktop2_gnb__DAxOJ li .___Desktop2_link___K0B7:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0px;
  width: 70px;
  height: 4px;
  border-radius: 4px;
  margin-bottom: 0px;
  background: #fe6b37;
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  transform: scaleX(0);
  transform-origin: bottom center;
  margin-left: -35px;
}
.___Desktop2_gnb__DAxOJ li:hover .___Desktop2_link___K0B7:before {
  transform: scaleX(1);
}
.___Desktop2_depth2__NPssX {
  position: absolute;
  top: -300px;
  z-index: 2;
  transition: 0s 0.2s ease-in-out;
  margin-left: 40px;
}
.___Desktop2_gnb__DAxOJ li .___Desktop2_depth2__NPssX:before {
  content: '';
  position: absolute;
  top: -11px;
  left: -90px;
  width: 180px;
  height: 12px;
}
.___Desktop2_gnb__DAxOJ li .___Desktop2_depth2__NPssX ul {
  width: 180px;
  margin-left: -90px;
  padding: 6px 0;
  background: #fff;
  border-radius: 20px;
  box-shadow: 3.5px 6.1px 19px 0 rgba(43, 29, 72, 0.24);
  opacity: 0;
  transition: 0.2s ease-in-out;
}
.___Desktop2_gnb__DAxOJ li .___Desktop2_depth2__NPssX ul li {
  float: none;
}
.___Desktop2_gnb__DAxOJ li .___Desktop2_depth2__NPssX ul li + li {
  margin-left: 0;
  border-top: 1px solid #ddd;
}
.___Desktop2_gnb__DAxOJ li .___Desktop2_depth2__NPssX ul li a {
  display: block;
  height: 51px;
  padding-top: 0;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  text-align: center;
  line-height: 50px;
}
.___Desktop2_gnb__DAxOJ li .___Desktop2_depth2__NPssX ul li.___Desktop2_on__lKh1N a,
.___Desktop2_gnb__DAxOJ li .___Desktop2_depth2__NPssX ul li a:hover {
  color: #fe6b37;
}
.___Desktop2_gnb__DAxOJ li .___Desktop2_depth2__NPssX ul li a:before {
  display: none !important;
}
.___Desktop2_gnb__DAxOJ li:hover .___Desktop2_depth2__NPssX {
  top: 80px;
  transition: 0s ease-in-out;
}
.___Desktop2_gnb__DAxOJ li:hover .___Desktop2_depth2__NPssX ul {
  opacity: 1;
}
.___Desktop2_gnb__DAxOJ li .___Desktop2_depth2__NPssX:hover {
  display: block;
}
.___Desktop2_sign-area__5h6ww {
  display: inline-flex;
  gap: 20px;
  padding: 15px 0px;
  height: 100%;
}

.___Desktop2_cs-area__RiU9h {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.___Desktop2_btn-cs-center__KCVkx {
  display: inline-flex;
  align-items: center;

  position: relative;

  font-size: 24px;
  font-weight: 500;
  color: #000;
  line-height: 20px;
  vertical-align: top;
}

.___Desktop2_btn-cs-icon__kN7ZJ {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  background: #e7e7e7;
}

.___Desktop2_signButtons__e5tiv {
  display: inline-flex;
  align-items: center;
  height: 100%;
  gap: 10px;
}

.___Desktop2_signInButton__RQZmA {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 27px;
  font-size: 18px;
  color: #000;
  line-height: 52px;
  transition: 0.2s ease-in-out;
  cursor: default;
  font-weight: 500;
  padding-right: 12px;
}
.___Desktop2_signInButton__RQZmA:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 30px;
  margin: 0px auto;
}

.___Desktop2_sign-area__5h6ww .___Desktop2_btn__LK4CL + .___Desktop2_btn__LK4CL {
  margin-left: 10px;
}
.___Desktop2_sign-area__5h6ww .___Desktop2_loginDetail__y6snp {
  position: absolute;
  top: -300px;
  left: 50%;
  z-index: 2;
  width: 180px;
  margin-left: -90px;
  padding: 6px 0;
  background: #fff;
  border-radius: 20px;
  box-shadow: 3.5px 6.1px 19px 0 rgba(43, 29, 72, 0.24);
  opacity: 0;
  transition:
    opacity 0.2s ease-in-out, top 0s 0.3s ease-in-out;
}
.___Desktop2_loginDetail__y6snp a {
  display: block;
  height: 51px;
  padding-top: 0;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  text-align: center;
  line-height: 50px;
}
.___Desktop2_loginDetail__y6snp a + a {
  border-top: 1px solid #ddd;
}

.___Desktop2_loginDetail__y6snp a:hover {
  color: #fe6b37;
}
.___Desktop2_signInButton__RQZmA:hover .___Desktop2_loginDetail__y6snp {
  top: 74px;
  opacity: 1;
  transition:
    opacity 0.2s ease-in-out, top 0s ease-in-out;
}

.___Desktop2_demoButton__H4hzN {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  padding: 0px 24px;
  border-radius: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 52px;
  text-align: center;
  transition: 0.2s ease-in-out;
}
@keyframes ___Desktop2_slidePhrase__neDsQ {
  0% {
    transform: translateY(-40px);
  }
  35% {
    transform: translateY(-40px);
  }
  50% {
    transform: translateY(0px);
  }
  85% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(40px);
  }
}
.___Desktop2_signUpButton__aggJT {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 215px;
  color: white;
  border: 1px solid #fe6b37;
  background: #fe6b37;
  padding: 0px 24px;
  border-radius: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 52px;
  text-align: center;
  transition: 0.2s ease-in-out;
  transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  overflow: hidden;
}
.___Desktop2_signUpButton__aggJT .___Desktop2_overlay__680KM {
    position: absolute;
    width: 160px;
    z-index: 1;
    height: 100%;
    background: linear-gradient(0deg, #fe6b37 0%, transparent 30%, transparent 70%, #fe6b37 100%);
  }
.___Desktop2_signUpButton__aggJT .___Desktop2_signUpCatchPhrase__3Q2QP {
    line-height: 40px;
    display: flex;
    flex-direction: column;
    animation: ___Desktop2_slidePhrase__neDsQ 4.5s linear infinite;
    transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  }

._______Mobile_logoImage__X3yLD {
  min-width: 0px;
  display: inline-flex;
  flex-shrink: 1;
}

@font-face {
font-family: '__NotoSans_673888';
src: url(/_next/static/media/1dab7b94dce2a2a7-s.p.woff) format('woff');
font-display: block;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: '__NotoSans_673888';
src: url(/_next/static/media/ca651d663b42c88c-s.p.woff) format('woff');
font-display: block;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__NotoSans_673888';
src: url(/_next/static/media/dc5241a5f94bd6d7-s.p.woff) format('woff');
font-display: block;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__NotoSans_673888';
src: url(/_next/static/media/7a59482918ee2e05-s.p.woff) format('woff');
font-display: block;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__NotoSans_673888';
src: url(/_next/static/media/08624ff9b41b0947-s.p.woff) format('woff');
font-display: block;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__NotoSans_673888';
src: url(/_next/static/media/53b8a47369181403-s.p.woff) format('woff');
font-display: block;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__NotoSans_Fallback_673888';src: local("Arial");ascent-override: 110.25%;descent-override: 30.41%;line-gap-override: 0.00%;size-adjust: 105.22%
}.__className_673888 {font-family: '__NotoSans_673888', '__NotoSans_Fallback_673888', Noto Sans KR
}.__variable_673888 {--notoSansKR: '__NotoSans_673888', '__NotoSans_Fallback_673888', Noto Sans KR
}

.___Mobile_header__kJhP_ {
  position: absolute;
}
.___Mobile_header__kJhP_ {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 52px;
  padding: 0 10px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;
}
.___Mobile_header__kJhP_ .___Mobile_section__o7eGm:after {
  content: '';
  display: block;
  clear: both;
}
.___Mobile_header__kJhP_ h1 {
  flex: 0 1 auto;
  width: 50px;
  height: 16px;
}
.___Mobile_header__kJhP_ h1 a {
  display: block;
}
.___Mobile_header__kJhP_ h1 img {
  display: block;
  width: 100%;
  height: 100%;
}
.___Mobile_header__kJhP_ > .___Mobile_gnb__BtaDw {
  flex: 0 1 auto;
  margin: 0 8px 0 auto;
}
.___Mobile_header__kJhP_ > .___Mobile_gnb__BtaDw li {
  position: relative;
}

.___Mobile_header__kJhP_ > .___Mobile_logoImage__TuGhy > img {
  min-width: 0;
  flex-shrink: 1;
}

.___Mobile_header__kJhP_ .___Mobile_gnb__BtaDw .___Mobile_btn-toggle__cVf7o {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  transition: color 0.2s ease-in-out;
  padding: 0px;
  word-break: keep-all;
}

.___Mobile_header__kJhP_ .___Mobile_gnb__BtaDw .___Mobile_depth2__Bqv1F {
  display: none;
  position: absolute;
  top: 33px;
  right: calc(50% - 60px);
  width: 91px;
  padding: 15px 0 20px 14px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 6px 0 rgba(38, 29, 72, 0.25);
}
.___Mobile_header__kJhP_ .___Mobile_gnb__BtaDw .___Mobile_depth2__Bqv1F li + li {
  margin-top: 9px;
}
.___Mobile_header__kJhP_ .___Mobile_gnb__BtaDw .___Mobile_depth2__Bqv1F a {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #3e3e40;
  line-height: 20px;
  transition: color 0.2s ease-in-out;
}
.___Mobile_header__kJhP_ .___Mobile_gnb__BtaDw .___Mobile_depth2__Bqv1F a.___Mobile_on__RIzzF {
  -webkit-tap-highlight-color: transparent;
  font-weight: bold;
  color: #e95a0c;
}
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
}
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf:after {
  content: '';
  display: block;
  clear: both;
}
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf .___Mobile_btn-login-wrap__gUj16 {
  position: relative;
  margin-right: 4px;
}
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf .___Mobile_btn-login__R8FVl {
  width: 60px;
  height: 28px;
  border: 1px solid #666;
  border-radius: 14px;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  transition: 0.2s ease-in-out;
}
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf .___Mobile_btn-login__R8FVl.___Mobile_on__RIzzF,
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf .___Mobile_btn-login__R8FVl.___Mobile_hover__9pZtB {
  border-color: #e95a0c;
  color: #e95a0c;
}
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf .___Mobile_btn-login-wrap__gUj16 .___Mobile_login-detail__1BMRS {
  display: none;
  position: absolute;
  top: 33px;
  left: 0;
  width: 105px;
  padding: 15px 0 20px 14px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 6px 0 rgba(38, 29, 72, 0.25);
}
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf .___Mobile_btn-login-wrap__gUj16 .___Mobile_login-detail__1BMRS a {
  display: block;
  font-size: 14px;
  color: #3e3e40;
  line-height: 20px;
  transition: color 0.2s ease-in-out;
}
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf .___Mobile_btn-login-wrap__gUj16 .___Mobile_login-detail__1BMRS a + a {
  margin-top: 9px;
}
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf .___Mobile_btn-login-wrap__gUj16 .___Mobile_login-detail__1BMRS a.___Mobile_on__RIzzF,
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf .___Mobile_btn-login-wrap__gUj16 .___Mobile_login-detail__1BMRS a:hover {
  -webkit-tap-highlight-color: transparent;
  font-weight: bold;
  color: #e95a0c;
}
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf .___Mobile_btn-join__TsY4w {
  flex: 0 1 auto;
  display: inline-flex;
  align-items: center;
  /* width: 90px; */
  height: 28px;
  padding: 0px 16px;
  /* padding-top: 5px; */
  background: #402578;
  border-radius: 14px;
  font-size: 12px;
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 18px;
  transition: 0.2s ease-in-out;
}
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf .___Mobile_btn-join__TsY4w.___Mobile_hover__9pZtB {
  background: #e95a0c;
}
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf .___Mobile_btn-cs-center__fvWv2 {
  flex: 0 1 auto;
  width: 32px;
  height: 32px;
  margin: 0 0 0 6px;
  background-size: 32px 32px;
  border-radius: 32px;
  text-indent: -9999em;
  transition: 0.2s ease-in-out;
}
.___Mobile_header__kJhP_ .___Mobile_sign-area__ZKedf .___Mobile_btn-cs-center__fvWv2.___Mobile_hover__9pZtB {
  background-image: url('/public/assets/mobile/images/icon/icon_cs2.svg');
}
.___Mobile_header__kJhP_.___Mobile_sub__HRs3s {
  height: 52px;
  padding-top: 11px;
  background: #ff663f;
  border-bottom: 0;
}
.___Mobile_header__kJhP_.___Mobile_sub__HRs3s p {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 29px;
}

.___Mobile_services__wR90U {
  display: block;
}

